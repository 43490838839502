<template>
  <!-- 传感器 -->
  <div class="role_index" id="roleList">
    <div class="role_content">
      <!--<div class="role_button">
        <el-input
          placeholder="所属企业"
          style="width: 250px"
          v-model="searchForm.enterpriseName"
          clearable
        ></el-input>
        <el-input
          placeholder="物联网编码"
          class="ml20"
          style="width: 250px"
          v-model="searchForm.deviceType"
          clearable
        ></el-input>

        <el-button type="primary" class="ml30" @click="search" style="margin-top: 20px"
          >查询</el-button
        >

      </div>-->
      <div style="height: 20px; background: #f6f7f9"></div>
      <div style="padding: 0 20px">
        <el-button type="primary" @click="addRole" style="margin: 20px 0"
          >新增</el-button
        >
        <!-- <h4 style="padding-top: 20px; margin-bottom: 20px">角色列表</h4> -->
        <el-table border :data="tableData">
            <!-- <el-table-column
                type="selection"
                width="55">
            </el-table-column> -->
          <el-table-column prop="enterpriseName" label="企业"></el-table-column>
          <el-table-column prop="deviceType" label="设备类型">
            <template slot-scope="scope">
              <div>{{scope.row.deviceType==1?'湿度':'温度'}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="maxValue" label="上限值"></el-table-column>
          <el-table-column prop="minValue" label="下限值"></el-table-column>
          <el-table-column prop="remark" label="备注"></el-table-column>
          <el-table-column prop="name" label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="editRole(scope.row)" size="small"
                >编辑</el-button
              >
              <el-popconfirm
                icon="el-icon-info"
                icon-color="red"
                @confirm="delRole(scope.row.id)"
                title="是否确认删除此设备?"
              >
                <el-button
                  slot="reference"
                  type="text"
                  style="margin-left: 12px"
                  size="small"
                  >删除</el-button
                >
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: right">
        <div style="margin-top: 12px; display: inline-block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageInfo.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageInfo.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <el-dialog :title="dialogTitle" width="40%" :visible.sync="roleDialog">
      <el-form
        :model="form"
        ref="cdForm"
        style="width: 80%; margin: 20px auto 0"
        :rules="rules"
        label-width="95px"
      >
        <el-form-item label="企业" prop="enterpriseId">
          <el-select v-model="form.enterpriseId" @change="selectChange" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.enterpriseId"
              :label="item.enterpriseName"
              :value="item.enterpriseId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备类型" prop="deviceType">
          <el-select v-model="form.deviceType" placeholder="请选择">
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上限值" prop="maxValue">
          <el-input type="num" v-model="form.maxValue" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="下限值" prop="minValue">
          <el-input type="num" v-model="form.minValue" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="form.remark"
            type="textarea"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button @click="roleDialog = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
        searchForm: {
            enterpriseId: '',
            deviceType: '',
        },
      options: [],
      options1: [
        {
          label: '湿度',
          value: 1
        },
        {
          label: '温度',
          value: 2
        },
      ],
      tableData: [],
      dialogFormVisible: false,
      form: {},
      dialogTitle: "新增设置阈值规则",
      roleDialog: false,
      pageInfo: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      rules: {
        enterpriseId: {
          required: true,
          message: "请选择企业",
          trigger: "blur",
        },
        deviceType: {
          required: true,
          message: "请选择设备类型",
          trigger: "blur",
        },
        maxValue: {
          required: true,
          message: "请输入上限值",
          trigger: "blur",
        },
        minValue: {
          required: true,
          message: "请输入下限值",
          trigger: "blur",
        },
        
      },
    };
  },
  mounted() {
    this.search();
    this.enterprise();
    if (window.innerHeight) {
      var winHeight = window.innerHeight;
    } else if (document.body && document.body.clientHeight) {
      var winHeight = document.body.clientHeight;
    }
    document.getElementById("roleList").style.minHeight = winHeight - 90 + "px";
  },
  methods: {
    search() {
      let params = {
        pageNo: this.pageInfo.pageNo,
        pageSize: this.pageInfo.pageSize,
      };
      this.qa.thresholdQueryForPageList(params).then((res) => {
        if (res.respCode == "0000") {
          this.tableData = res.data;
          this.pageInfo.total = res.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    enterprise () {
      this.qa.datascreen3EnterpriseMapInfoQueryForPageList({pageSize:100,status:1}).then((res) => {
        console.log(res)
        res.data.push({enterpriseId: 8643, enterpriseName: '鱼河农场'});
        res.data.push({enterpriseId: 9999, enterpriseName: '一升小米'})
        this.options = res.data;
      })
    },
    selectChange(){
      console.log(this.form.enterpriseId)
      for(let i = 0; i < this.options.length; i++) {
        if(this.form.enterpriseId == this.options[i].enterpriseId) {
          this.form.enterpriseName = this.options[i].enterpriseName;
        }
      }
    },
    addRole() {
      this.dialogTitle = "新增阈值规则";
      this.form = {
        enterpriseId: '',
        enterpriseName: '',
        deviceType: '',
        maxValue: '',
        minValue: '',
        remark: '',
      };
      this.roleDialog = true;
    },
    editRole(row) {
      this.dialogTitle = "编辑阈值规则";
      this.form = {
        id: row.id,
        enterpriseId: row.enterpriseId,
        enterpriseName: row.enterpriseName,
        deviceType: row.deviceType,
        maxValue: row.maxValue,
        minValue: row.minValue,
        remark: row.remark,
      };
      this.roleDialog = true;
    },
    delRole(id) {
      this.qa.thresholdDoDeleteById({ id: id }).then((res) => {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.search();
      });
    },
    add() {
      this.$refs.cdForm.validate((valid) => {
        if (valid) {
          this.form.maxValue = Number(this.form.maxValue);
          this.form.minValue = Number(this.form.minValue);
          if (this.dialogTitle == "新增阈值规则") {
            this.form.redisKey = this.form.deviceType == 1 ? 'SD:' : 'WD:';
            this.qa.thresholdDoInsert(this.form).then((res) => {
              if (res.respCode == "0000") {
                this.$message({
                  message: "新增阈值规则成功！",
                  type: "success",
                });
                this.roleDialog = false;
                this.search();
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            this.form.redisKey = this.form.deviceType == 1 ? 'SD:' : 'WD:';
            this.qa.thresholdDoUpdate(this.form).then((res) => {
              if (res.respCode == "0000") {
                this.$message({
                  message: "编辑阈值规则成功！",
                  type: "success",
                });
                this.roleDialog = false;
                this.search();
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        } else {
          return false;
        }
      });
    },

    handleSizeChange(page) {
      this.pageInfo.pageSize = page;
      this.search();
    },
    handleCurrentChange(page) {
      this.pageInfo.pageNo = page;
      this.search();
    },
  },
};
</script>

<style scoped>
/* .role_index {
      width: 100%;
      padding: 20px 24px;
  } */
.role_content {
  background: #fff;
}
.role_content > h4 {
  padding: 24px 24px 10px;
  font-size: 16px;
  color: #1d1e1f;
  font-weight: 400;
  line-height: 22px;
  border-bottom: 1px solid #efefef;
}
.role_button {
  padding: 10px 24px;
}
</style>